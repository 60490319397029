// responsive
@mixin respond($breakpoint) {
  @if $breakpoint== sm-mobile {
    @media (max-width: 480px) {
      @content;
    }
  }

  @if $breakpoint== mobile {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint == ipad {
    @media (max-width: 50.625em) {
      // 992px
      @content;
    }
  }

  @if $breakpoint== tablet {
    @media (max-width: 62em) {
      // 992px
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (max-width: 80em) {
      //1280px
      @content;
    }
  }
  @if $breakpoint == smallLaptop {
    @media (max-width: 102.4rem) {
      //1024
      @content;
    }
  }
  @if $breakpoint == bigLaptop {
    @media (max-width: 128rem) {
      //1290
      @content;
    }
  }
}

// center
@mixin center {
  display: grid;
  place-items: center;
}

// flex
@mixin flex($direction, $alignItems, $justifyContent) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

// text ellipsis
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fwcontainer {
  max-width: 1080px !important;
  margin: 0 auto;
}

@mixin  linearGradient($width, $height, $borderRadius) {
  border-radius: $borderRadius;
  width: $width;
  height: $height;
  background: linear-gradient(89deg, #F1EFEF 0%, #F9F8F8 53.13%, #E7E5E5 99.22%);
}
@mixin skeleton($width, $height) {
  animation: skeleton-loading 1s linear infinite alternate;
  width: $width;
  display: inline;
  height: $height;
  border-radius: 0.25rem;
  display: inline-block;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
