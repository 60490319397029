@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/100.ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/100.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/300.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/300.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/500.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/600.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/700.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/800.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/900.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/300.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  overflow-y: auto !important;
  overflow-x: hidden;
  background-color: white;
  // max-width: 1280px;

  .app-content {
    &.sticky {
      padding-top: 6.6rem;
    }

    &.fixed-width {
      max-width: 1086px;
      margin: 0 auto;
    }
  }

  &.noscroll {
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;
  }

  &.noscroll-x {
    max-width: 100vw;
    overflow-x: hidden !important;
  }
}
.disableScroll {
  overflow-y: hidden !important;
}

html {
  font-size: 10px;
}

html:focus-within {
  scroll-behavior: smooth;
}

input,
button {
  all: unset;
}

img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}

button {
  cursor: pointer;
}

.moe-push-class {
  text-align: center;
  .ng-binding {
    display: flex;
    justify-content: center;
  }
}

input + .input-cleaner {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #ccc;
  font-size: $large;
  cursor: pointer;
  transform: translateY(-50%);
}

input:placeholder-shown + .input-cleaner {
  display: none;
}

.AppStoreBtn_Globals {
  width: 142px;
  height: 40px;
  background-color: black;
  border-radius: 6px;
  border: 1px solid #fff;
  font-family: 'Avenir';
  .container {
    .side-by-side {
      display: flex;
      .first-div {
        display: flex;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
      }
      .second-div {
        flex: 1;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .sub-div-2 {
          font-size: 15px;
          font-weight: 500;
          margin-top: -5px;
        }
        .is-footer-margin {
          margin-top: 0;
        }
      }
    }
  }
}

.shadowOnHover:hover {
  filter: drop-shadow(0px 0px 2px rgb(158, 158, 158));
}

.sliderArrowIconGlobal {
  width: 8rem !important;
}

.swiper {
  min-width: 100%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
.productsLink_NEW {
  transition: transform 0.2s;
  gap: 8.5px;
}
.productsLink_NEW:hover {
  transform: scale(1.02);
  font-weight: bold;
}
.__floater.__floater__open {
  padding: 8px 0 0 !important;
}