@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  font-family: Avenir, sans-serif;
  position: fixed;
  bottom: 0;
  right: 25px;
  width: 100%;
  max-width: 400px;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
  background-color: #ebecfe;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  z-index: 2147483602;

  &.visible {
    transform: translateY(0);
  }

  .playerWrapper {
    position: relative;
    aspect-ratio: 16 / 9;

    :global(.shaka-controls-container),
    :global(#movie_player) {
      display: none;
    }

    .playerControlsWrapper {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
      z-index: 10;
      color: white;
      font-size: 20px;

      .closeBtn,
      .expandBtn {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 28px;
        line-height: 1;
        height: 25px;
      }

      .expandBtn {
        left: 16px;
        right: auto;
      }

      .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 28px;
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      .playerControlsWrapper {
        display: block;
      }
    }
  }

  .videoInfoWrapper {
    padding: 12px 16px;

    .videoName {
      color: var(--alias-grayscale-header-weak, #262338);
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
    }

    .chapterName {
      color: #a0a3bd;
      font-size: 11px;
      font-weight: 500;
    }
  }
}
